.piece {
  text-align: center;
  position: relative;
  border-radius: 10px;
  border: 100px;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 20px;
  background-color: steelblue;
}

.opensea {
  border: 3px solid white;
  border-radius: 30px;
  width: 50px;
  margin: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.opensea:hover {
  border-color: rgb(0, 49, 184);
}

#loader {
  margin: auto;
  display: block;
  width: 250px;
  height: 250px;
}

#pieceDisplay {
  position: -webkit-sticky;
  position: sticky;
  text-align: center;
  top: 0;
  padding: 30px;
  padding-top: 20px;
  z-index: 2;
  margin-left: 10%;
  margin-right: 10%;
  background-color:#1890FF;
  border-radius: 20px;
  border: 5px solid #2B313C;
}

.equipButton {
  font-size: 20px;
  width: 100px;
}

.displayedPiece {
  display: inline;
}